<template>
    <div class="sector">
        <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="500">
            <v-card>
                <v-toolbar color="primary" dark> Bullying </v-toolbar>
                <v-card-text class="info-bullying">
                    <h2>{{ bullying.title }}</h2>
                    <p>{{ bullying.text }}</p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="sector-head">
            <h1 class="sector-head-title">{{ titles.sector }}</h1>
        </div>
        <div class="box-table">
            <v-data-table
                :headers="headers"
                :items="campaigns"
                :page.sync="page"
                sort-by="name"
                :items-per-page="itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pageCount = $event">
                <template v-slot:[`item.created_at`]="{ item }">
                    {{ formatDate(item.created_at) }}
                </template>
                <template v-slot:[`item.expiration`]="{ item }">
                    {{ formatDate(item.expiration) }}
                </template>
                <template v-slot:[`item.expired`]="{ item }">
                    <v-icon
                        :color="getIconExpired(item.created_at, item.expiration).color">
                        {{ getIconExpired(item.created_at, item.expiration).icon }}
                    </v-icon>
                </template>
                <template v-slot:[`item.finalized`]="{ item }">
                    <v-icon :color="getIconFinalized(item.finalized).color">
                        {{ getIconActivated(item.finalized).icon }}
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        color="secondary"
                        class="btn-des"
                        small
                        rounded
                        dark
                        @click="monitore(item)">
                        <v-icon left> mdi-eye-circle-outline </v-icon>
                        Monitorar
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import campaignsMock from '@/modules/manager/mocks/campaigns.manager.mock';
    import messages from '@/mocks/info.mock';

    export default {
        name: 'ListCamapaign',
        data() {
            return {
                dialog: false,
                bullying: {
                    id: null,
                    role: null,
                    title: null,
                    text: null
                },
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                titles: {
                    sector: 'Campanhas'
                },
                headers: [
                    { text: 'Nome', value: 'name' },
                    { text: 'Grupo', value: 'group' },
                    { text: 'Dt. Criação', value: 'created_at', align: 'center' },
                    { text: 'Dt. Expiração', value: 'expiration', align: 'center' },
                    { text: 'Expirado?', value: 'expired', align: 'center' },
                    { text: 'Finalizada?', value: 'finalized', align: 'center' },
                    { text: 'Ações', value: 'actions', align: 'center', sortable: false }
                ],
                campaigns: [...campaignsMock.campaigns]
            };
        },
        mounted() {
            this.setMessageBullying();
        },
        methods: {
            monitore() {
                this.$router.push({ name: 'campaignMonitore' });
            },
            setMessageBullying() {
                const messagesFiltereds = messages.messages.filter(
                    (e) => e.role === 'ALL' || e.role === 'COMPANY'
                );

                const randomize = (min, max) => {
                    return Math.floor(Math.random() * (max - min)) + min;
                };

                const totalMessages = messagesFiltereds.length;

                const index = randomize(0, totalMessages);

                this.bullying = messagesFiltereds[index];

                this.dialog = true;
            }
        },
        computed: {
            formatDate() {
                return (date) => {
                    return moment(date).format('DD/MM/YYYY HH:mm');
                };
            },
            getIconActivated() {
                return (activated) => {
                    return {
                        icon: 'mdi-bookmark-check',
                        color: activated ? 'green' : '#d0d0d0'
                    };
                };
            },
            getIconExpired() {
                return (dateCreation, dateExpiration) => {
                    return {
                        icon: 'mdi-clock',
                        color: moment(dateExpiration).isBefore(dateCreation)
                            ? 'red'
                            : '#d0d0d0'
                    };
                };
            },
            getIconFinalized() {
                return (finalized) => {
                    return {
                        icon: 'mdi-clock',
                        color: finalized ? 'red' : '#d0d0d1'
                    };
                };
            }
        }
    };
</script>

<style scoped>
    .info-bullying h2 {
        margin-top: 20px;
        font-size: 28px;
        line-height: 1.3em;
        color: #000;
    }

    .info-bullying p {
        margin-top: 20px;
        font-size: 18px;
        color: #000;
        font-family: arial;
    }

    .sector-head {
        margin-bottom: 20px;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }
</style>
