var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sector"},[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Bullying ")]),_c('v-card-text',{staticClass:"info-bullying"},[_c('h2',[_vm._v(_vm._s(_vm.bullying.title))]),_c('p',[_vm._v(_vm._s(_vm.bullying.text))])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Fechar")])],1)],1)],1),_c('div',{staticClass:"sector-head"},[_c('h1',{staticClass:"sector-head-title"},[_vm._v(_vm._s(_vm.titles.sector))])]),_c('div',{staticClass:"box-table"},[_c('v-data-table',{staticClass:"data-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.campaigns,"page":_vm.page,"sort-by":"name","items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.expiration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expiration))+" ")]}},{key:"item.expired",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconExpired(item.created_at, item.expiration).color}},[_vm._v(" "+_vm._s(_vm.getIconExpired(item.created_at, item.expiration).icon)+" ")])]}},{key:"item.finalized",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconFinalized(item.finalized).color}},[_vm._v(" "+_vm._s(_vm.getIconActivated(item.finalized).icon)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-des",attrs:{"color":"secondary","small":"","rounded":"","dark":""},on:{"click":function($event){return _vm.monitore(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-eye-circle-outline ")]),_vm._v(" Monitorar ")],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }